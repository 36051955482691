import {
  CardStackMinusIcon,
  CounterClockwiseClockIcon,
  GearIcon,
  LightningBoltIcon,
  ReaderIcon,
} from "@radix-ui/react-icons";
import { NavLinkGroup } from "@/components/NavLinkGroup";
import { InboxIcon } from "@/components/icons/InboxIcon";
import { ReceiptIcon } from "@/components/icons/ReceiptIcon";
import { BagIcon } from "@/components/icons/BagIcon";
import { GovernmentIcon } from "@/components/icons/GovernmentIcon";
import { cn } from "@/util/utils";
import { useSidebarStore } from "@/store/SidebarStore";
import { CompassIcon } from "@/components/icons/CompassIcon";
import { KeyIcon } from "@/components/icons/KeyIcon";

function SideNav() {
  const { collapsed } = useSidebarStore();

  return (
    <nav
      className={cn("flex flex-col gap-5", {
        "items-center": collapsed,
      })}
    >
      <NavLinkGroup
        title="Build"
        links={[
          {
            label: "Discover",
            to: "/discover",
            icon: <CompassIcon className="size-6" />,
          },
          {
            label: "Workflows",
            to: "/workflows",
            icon: <LightningBoltIcon className="size-6" />,
          },
          {
            label: "History",
            to: "/history",
            icon: <CounterClockwiseClockIcon className="size-6" />,
          },
        ]}
      />
      <NavLinkGroup
        title={"Agents"}
        links={[
          {
            label: "Contact Forms",
            to: "/forms",
            icon: <ReaderIcon className="size-6" />,
          },
          {
            label: "Jobs",
            to: "/jobs",
            icon: <InboxIcon className="size-6" />,
          },
          {
            label: "Invoices",
            to: "/invoices",
            disabled: true,
            beta: true,
            icon: <ReceiptIcon className="size-6" />,
          },
          {
            label: "Purchasing",
            to: "/purchasing",
            disabled: true,
            icon: <BagIcon className="size-6" />,
          },
          {
            label: "Government",
            to: "/government",
            disabled: true,
            icon: <GovernmentIcon className="size-6" />,
          },
        ]}
      />
      <NavLinkGroup
        title={"General"}
        links={[
          {
            label: "Billing",
            to: "/billing",
            icon: <CardStackMinusIcon className="size-6" />,
          },
          {
            label: "Credentials",
            to: "/credentials",
            icon: <KeyIcon className="size-6" />,
          },
          {
            label: "Settings",
            to: "/settings",
            icon: <GearIcon className="size-6" />,
          },
        ]}
      />
    </nav>
  );
}

export { SideNav };
