import { PageLayout } from "@/components/PageLayout";
import { CredentialsPage } from "@/routes/credentials/CredentialsPage";
import { DiscoverPage } from "@/routes/discover/DiscoverPage";
import { HistoryPage } from "@/routes/history/HistoryPage";
import { CreateNewTaskFormPage } from "@/routes/tasks/create/CreateNewTaskFormPage";
import { RetryTask } from "@/routes/tasks/create/retry/RetryTask";
import { StepArtifactsLayout } from "@/routes/tasks/detail/StepArtifactsLayout";
import { TaskActions } from "@/routes/tasks/detail/TaskActions";
import { TaskDetails } from "@/routes/tasks/detail/TaskDetails";
import { TaskParameters } from "@/routes/tasks/detail/TaskParameters";
import { TaskRecording } from "@/routes/tasks/detail/TaskRecording";
import { TasksPage } from "@/routes/tasks/list/TasksPage";
import { WorkflowEditor } from "@/routes/workflows/editor/WorkflowEditor";
import { WorkflowPage } from "@/routes/workflows/WorkflowPage";
import { WorkflowRun } from "@/routes/workflows/WorkflowRun";
import { WorkflowPostRunParameters } from "@/routes/workflows/workflowRun/WorkflowPostRunParameters";
import { WorkflowRunOutput } from "@/routes/workflows/workflowRun/WorkflowRunOutput";
import { WorkflowRunOverview } from "@/routes/workflows/workflowRun/WorkflowRunOverview";
import { WorkflowRunRecording } from "@/routes/workflows/workflowRun/WorkflowRunRecording";
import { WorkflowRunParameters } from "@/routes/workflows/WorkflowRunParameters";
import { Workflows } from "@/routes/workflows/Workflows";
import { WorkflowsPageLayout } from "@/routes/workflows/WorkflowsPageLayout";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { Billing } from "./routes/billing/Billing";
import { ComingSoonPage } from "./routes/forms/ComingSoonPage";
import { FormsPage } from "./routes/forms/FormsPage";
import { InvoicesPage } from "./routes/invoices/InvoicesPage";
import { JobsPage } from "./routes/jobs/JobsPage";
import { Root } from "./routes/root/Root";
import { RootLayout } from "./routes/root/RootLayout";
import { CloudSettings } from "./routes/settings/CloudSettings";
import { SignInPage } from "./routes/sign-in/SignInPage";
import { SignUpPage } from "./routes/sign-up/SignUpPage";

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: "/sign-in/*",
        element: <SignInPage />,
      },
      {
        path: "/sign-up/*",
        element: <SignUpPage />,
      },
      {
        path: "/",
        element: <RootLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/discover" />,
          },
          {
            path: "tasks",
            element: <PageLayout />,
            children: [
              {
                index: true,
                element: <TasksPage />,
              },
              {
                path: "create",
                element: <Outlet />,
                children: [
                  {
                    path: ":template",
                    element: <CreateNewTaskFormPage />,
                  },
                  {
                    path: "retry/:taskId",
                    element: <RetryTask />,
                  },
                ],
              },
              {
                path: ":taskId",
                element: <TaskDetails />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="actions" />,
                  },
                  {
                    path: "actions",
                    element: <TaskActions />,
                  },
                  {
                    path: "recording",
                    element: <TaskRecording />,
                  },
                  {
                    path: "parameters",
                    element: <TaskParameters />,
                  },
                  {
                    path: "diagnostics",
                    element: <StepArtifactsLayout />,
                  },
                ],
              },
            ],
          },
          {
            path: "workflows",
            element: <WorkflowsPageLayout />,
            children: [
              {
                index: true,
                element: <Workflows />,
              },
              {
                path: ":workflowPermanentId",
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="runs" />,
                  },
                  {
                    path: "edit",
                    element: <WorkflowEditor />,
                  },
                  {
                    path: "run",
                    element: <WorkflowRunParameters />,
                  },
                  {
                    path: "runs",
                    element: <WorkflowPage />,
                  },
                  {
                    path: ":workflowRunId",
                    element: <WorkflowRun />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to="overview" />,
                      },
                      {
                        path: "blocks",
                        element: <Navigate to="overview" />,
                      },
                      {
                        path: "overview",
                        element: <WorkflowRunOverview />,
                      },
                      {
                        path: "output",
                        element: <WorkflowRunOutput />,
                      },
                      {
                        path: "parameters",
                        element: <WorkflowPostRunParameters />,
                      },
                      {
                        path: "recording",
                        element: <WorkflowRunRecording />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "discover",
            element: <PageLayout />,
            children: [
              {
                index: true,
                element: <DiscoverPage />,
              },
            ],
          },
          {
            path: "history",
            element: <PageLayout />,
            children: [
              {
                index: true,
                element: <HistoryPage />,
              },
            ],
          },
          {
            path: "settings",
            element: <PageLayout />,
            children: [
              {
                index: true,
                element: <CloudSettings />,
              },
            ],
          },
          {
            path: "billing",
            element: <PageLayout />,
            children: [
              {
                index: true,
                element: <Billing />,
              },
            ],
          },
          {
            path: "forms",
            element: <PageLayout />,
            children: [
              {
                index: true,
                element: <FormsPage />,
              },
            ],
          },
          {
            path: "jobs",
            element: <PageLayout />,
            children: [
              {
                index: true,
                element: <JobsPage />,
              },
            ],
          },
          {
            path: "invoices",
            element: <PageLayout />,
            children: [
              {
                index: true,
                element: <InvoicesPage />,
              },
            ],
          },
          {
            path: "purchasing",
            element: <PageLayout />,
            children: [
              {
                index: true,
                element: (
                  <ComingSoonPage
                    title="Purchasing"
                    description="Skyvern's Purchasing Agent allows you to make payments on the web"
                  />
                ),
              },
            ],
          },
          {
            path: "government",
            element: <PageLayout />,
            children: [
              {
                index: true,
                element: (
                  <ComingSoonPage
                    title="Government"
                    description="Skyvern's Government Agent allows you to navigate any government websites"
                  />
                ),
              },
            ],
          },
          {
            path: "credentials",
            element: <PageLayout />,
            children: [
              {
                index: true,
                element: <CredentialsPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export { router };
