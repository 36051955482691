import { apiBaseUrl } from "@/util/env";

export const MAX_STEPS_MAX_VALUE = 75;

// this is a stupid fix probably should change
export const backendIsStaging =
  apiBaseUrl === "https://api-staging.skyvern.com/api/v1";

export const JOB_APPLICATION_WORKFLOW_WPID = backendIsStaging
  ? "wpid_353862309074493424"
  : "wpid_351487857063054716";

export const INVOICE_WORKFLOW_WPID = backendIsStaging
  ? "wpid_369161749055928862"
  : "wpid_369441201410076446";
