import { getClient } from "@/api/AxiosClient";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { Textarea } from "@/components/ui/textarea";
import { useCredentialGetter } from "@/hooks/useCredentialGetter";
import { CredentialSelector } from "@/routes/workflows/components/CredentialSelector";
import { INVOICE_WORKFLOW_WPID } from "@cloud/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReloadIcon, StarIcon } from "@radix-ui/react-icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { InvoicesRunHistory } from "./InvoicesRunHistory";
import { toast } from "@/components/ui/use-toast";
import { AxiosError } from "axios";

const invoicePageNavigationInstructionsPlaceholder =
  "Click the invoices link in the page / header / sidebar / footer\n";
const invoiceDownloadInstructionsPlaceholder =
  "Click the download button\nClick the invoice file\n";

const invoicesFormSchema = z.object({
  url: z
    .string()
    .min(1, {
      message: "URL is required",
    })
    .url({
      message: "Invalid URL",
    }),
  credential: z.string().min(1, {
    message: "Credential is required",
  }),
  invoicePageNavigationInstructions: z.string(),
  invoiceDownloadInstructions: z.string(),
  downloadStartDate: z.string(),
  downloadEndDate: z.string(),
  email: z.string().email({
    message: "Invalid email address",
  }),
});

type InvoicesFormValues = z.infer<typeof invoicesFormSchema>;

function InvoicesPage() {
  const queryClient = useQueryClient();
  const credentialGetter = useCredentialGetter();
  const form = useForm<InvoicesFormValues>({
    resolver: zodResolver(invoicesFormSchema),
    defaultValues: {
      url: "",
      credential: "",
      invoicePageNavigationInstructions: "",
      invoiceDownloadInstructions: "",
      downloadStartDate: "",
      downloadEndDate: "",
      email: "",
    },
  });

  const runInvoiceAgentMutation = useMutation({
    mutationFn: async (values: InvoicesFormValues) => {
      const client = await getClient(credentialGetter);
      const searchParams = new URLSearchParams();
      searchParams.set("template", "true");
      return client.post(
        `/workflows/${INVOICE_WORKFLOW_WPID}/run`,
        {
          data: {
            website_url: values.url,
            credentials: values.credential,
            how_to_find_invoices_page: values.invoicePageNavigationInstructions,
            how_to_download_an_invoice: values.invoiceDownloadInstructions,
            download_start_date: values.downloadStartDate,
            download_end_date: values.downloadEndDate,
            recipient: values.email,
          },
        },
        {
          params: searchParams,
        },
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["skyvernInvoicesWorkflows"],
      });
    },
    onError: (error: AxiosError) => {
      const detail = (error.response?.data as { detail?: string })?.detail;
      toast({
        title: "Could not run invoice agent",
        description: detail ? detail : error.message,
        variant: "destructive",
      });
    },
  });

  function onSubmit(values: InvoicesFormValues) {
    runInvoiceAgentMutation.mutate(values);
  }

  return (
    <div className="space-y-6">
      <h1 className="text-3xl">Invoices (Beta)</h1>
      <section className="flex items-stretch gap-8 bg-slate-elevation1 p-6">
        <div className="w-full">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="space-y-6 py-6">
                <FormField
                  control={form.control}
                  name="url"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Website URL</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder="https://www.example.com"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="credential"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Credential</FormLabel>
                        <FormControl>
                          <CredentialSelector {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="invoicePageNavigationInstructions"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>
                          How can Skyvern access the invoices page?
                        </FormLabel>
                        <FormControl>
                          <Textarea
                            {...field}
                            rows={3}
                            placeholder={
                              invoicePageNavigationInstructionsPlaceholder
                            }
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="invoiceDownloadInstructions"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>
                          How can Skyvern download the invoices?
                        </FormLabel>
                        <FormControl>
                          <Textarea
                            {...field}
                            rows={3}
                            placeholder={invoiceDownloadInstructionsPlaceholder}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="downloadStartDate"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Download Start Date</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="DD/MM/YYYY" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="downloadEndDate"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Download End Date</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="DD/MM/YYYY" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>
                          Email to receive the downloaded invoices
                        </FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="your@email.com" />
                        </FormControl>
                      </FormItem>
                    );
                  }}
                />
              </div>
              <div className="flex justify-end">
                <Button
                  type="submit"
                  className="w-24"
                  disabled={runInvoiceAgentMutation.isPending}
                >
                  {runInvoiceAgentMutation.isPending && (
                    <ReloadIcon className="mr-2 size-4 animate-spin" />
                  )}
                  Run
                </Button>
              </div>
            </form>
          </Form>
        </div>
        <div className="flex w-[448px] shrink-0 flex-col gap-6 rounded-lg bg-slate-elevation2 p-6">
          <h2 className="text-lg font-bold">Invoice Agent</h2>
          <h3>
            Skyvern can navigate to any website and download your invoices
          </h3>
          <Separator />
          <div className="space-y-4">
            <div className="flex items-center gap-6">
              <StarIcon className="size-6 shrink-0" />
              <div className="text-sm text-slate-300">
                Enter the website URL
              </div>
            </div>
            <div className="flex items-center gap-6">
              <StarIcon className="size-6 shrink-0" />
              <div className="text-sm text-slate-300">
                Select your credential
              </div>
            </div>
            <div className="flex items-center gap-6">
              <StarIcon className="size-6 shrink-0" />
              <div className="text-sm text-slate-300">
                Give instructions on how to find the page with the invoices
              </div>
            </div>
            <div className="flex items-center gap-6">
              <StarIcon className="size-6 shrink-0" />
              <div className="text-sm text-slate-300">
                Give instructions on how to select the right invoices
              </div>
            </div>
            <div className="flex items-center gap-6">
              <StarIcon className="size-6 shrink-0" />
              <div className="text-sm text-slate-300">
                Give instructions on how to download the invoices
              </div>
            </div>
            <div className="flex items-center gap-6">
              <StarIcon className="size-6 shrink-0" />
              <div className="text-sm text-slate-300">
                Enter an email address to receive the invoices
              </div>
            </div>
          </div>
        </div>
      </section>
      <InvoicesRunHistory />
    </div>
  );
}

export { InvoicesPage };
