import jobApplicationImage from "@/assets/job-application.png";
import contactFormsImage from "@/assets/contact-forms.png";
import purchasingImage from "@/assets/purchasing.png";
import entityLookupImage from "@/assets/entity-lookup.png";
import invoiceImage from "@/assets/invoice-downloading.png";

export const TEMPORARY_TEMPLATE_IMAGES: Record<string, string> = {
  wpid_353862309074493424: jobApplicationImage,
  wpid_351487857063054716: jobApplicationImage,
  wpid_356554024867713766: contactFormsImage,
  wpid_357290139535158898: purchasingImage,
  wpid_357290367168425660: entityLookupImage,
  wpid_369161749055928862: invoiceImage,
  wpid_369441201410076446: invoiceImage,
};
